import { VAlert } from 'vuetify/lib/components/VAlert';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.userPassword
      ? _c(
          "div",
          [
            _vm.passwordStrengthScoreData.progress.color &&
            _vm.passwordStrengthScoreData.progress.value
              ? _c(VProgressLinear, {
                  attrs: {
                    color: _vm.passwordStrengthScoreData.progress.color,
                    value: _vm.passwordStrengthScoreData.progress.value,
                  },
                })
              : _vm._e(),
            _vm.passwordStrengthScoreData.alert.type &&
            _vm.passwordStrengthScoreData.alert.text
              ? _c(
                  VAlert,
                  {
                    staticClass: "mt-5",
                    attrs: {
                      type: _vm.passwordStrengthScoreData.alert.type,
                      outlined: "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.passwordStrengthScoreData.alert.text) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }